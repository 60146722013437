<template>
  <div class="elite-tabs-wrapper-content OrganizationViewGlobal">
    <h3 class="mb-4">{{ organization.name }}</h3>
    <div class="row-el">
      <div class="row-el">
        <div class="organisation-image">
          <img
            v-if="organization.logo"
            :src="`${organization.logo}`"
            class="argon-image"
            style="width: 100%"
          />
          <img v-else src="/img/add-image.svg" class="argon-image default" />
        </div>
      </div>
      <div class="row-el">
        <div class="group-grid">
          <p class="labe-red">
            {{ $t("COMMON.INFORMATIONS_SUR_L_ORGANISATION") }}
          </p>
          <div class="grid-2">
            <dl class="row">
              <dt class="row-el">{{ $t("COMMON.NAME") }}</dt>
              <dd class="row-el">
                {{ organization.name }}
              </dd>
            </dl>
            <dl class="row">
              <dt class="row-el">{{ $t("COMMON.EXCERPT") }}</dt>
              <dd class="row-el">
                <div v-html="organization.excerpt"></div>
              </dd>
            </dl>
            <dl class="row">
              <dt class="row-el">{{ $t("COMMON.EMAIL") }}</dt>
              <dd class="row-el">
                {{ organization.email }}
              </dd>
            </dl>
            <dl class="row">
              <dt class="row-el">
                {{ $t("COMMON.PHONE") }}
              </dt>
              <dd class="row-el">
                {{
                  organization.phone_type
                    ? $t(`COMMON.PHONE_TYPE_${organization.phone_type}`)
                    : null
                }}:
                {{ organization.phone }}
                {{
                  organization.phone_extension
                    ? ` (ext: ${organization.phone_extension})`
                    : null
                }}
              </dd>
            </dl>
            <dl class="row">
              <dt class="row-el">
                {{ $t("COMMON.OTHER_PHONES") }}
              </dt>
              <dd class="row-el">
                <span
                  v-for="(line, index) in organization.other_phones"
                  :key="index"
                >
                  {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
                  {{ line.phoneNumber }}
                  {{ line.extension ? ` (ext: ${line.extension})` : null }}
                  <br />
                </span>
              </dd>
            </dl>
            <dl class="row">
              <dt class="row-el">{{ $t("COMMON.ADDRESS") }}</dt>
              <dd class="row-el">
                {{ organization.address }}
              </dd>
            </dl>
            <dl
              class="row"
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
            >
              <dt class="row-el">{{ $t("COMMON.RESELLER") }}</dt>
              <dd class="row-el">
                <reseller :reseller="organization.reseller" />
              </dd>
            </dl>
            <dl
              class="row"
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
            >
              <dt class="row-el">{{ $t("COMMON.OWNER") }}</dt>
              <dd class="row-el">
                <router-link :to="$objectViewRoute(organization.owner)">
                  {{
                    `${organization.owner.firstname} ${organization.owner.lastname} - ${organization.owner.email}`
                  }}
                </router-link>
              </dd>
            </dl>
          </div>
        </div>
        <div class="group-grid">
          <p class="labe-red">
            {{ $t("COMMON.INFORMATIONS_MARCHAND") }}
          </p>
        </div>
      </div>
      <div class="grid-2">
        <dl class="row">
          <dt class="col-sm-12">{{ $t("COMMON.DEFAULT_TAXES") }}</dt>
          <dd class="col-sm-12">
            <dl class="row" v-for="(tax, key) in organization.taxes" :key="key">
              <dt class="row-el">{{ tax.name }}</dt>
              <dd class="row-el" v-if="tax.type === TAX_TYPE_PERCENTAGE">
                {{ tax.value }} %
              </dd>
              <dd class="row-el" v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>
        <dl class="row">
          <dt class="row-el">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="row-el" v-if="organization.created_at">
            {{ $formatDate(organization.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="row-el">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="row-el" v-if="organization.updated_at">
            {{ $formatDate(organization.updated_at) }}
          </dd>
        </dl>
      </div>

      <div class="grid-1 billingInformations">
        <div class="mb-2 mt-4">
          <!-- <h3>{{ $t("COMMON.BILLING_ADDRESS") }}</h3> -->
          <p class="labe-red">
            {{ $t("COMMON.BILLING_ADDRESS") }}
          </p>
          <billing-informations-view :billingInformations="organization" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "organization-view-global",

  components: { BillingInformationsView },

  props: ["organization"],

  data() {
    return {
      models: models,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
